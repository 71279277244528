import * as React from "react"
import { StyledLink } from '../components/Buttons/Button'
import Layout from '../layouts/Layout'

// markup
const NotFoundPage = () => {
  return (
    <Layout title="404">
      <>
      <div className="container py-4">
        <StyledLink arrow="left" noOutline to='/'>Back TO Home</StyledLink>
      </div>
      <div className="bg-red py-16 mb-16">
        <div className="container">
          <h1 className="text-white">404 Error</h1>
          <h2 className="text-white">We&rsquo;re sorry, this page does not exist.</h2>
          <p className="text-white">The requested URL was not found on the server. Please return to the eGenesis home page. </p>
        </div>

      </div>
      </>
    </Layout>
  )
}

export default NotFoundPage
